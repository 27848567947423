import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Screen from '../components/screen/screen';
import Hero from "../components/hero";
import Partnership from "../components/partnership";
import Why from "../components/why";
import VideoSection from "../components/videosection";
import FAQ from "../components/faq";

const IndexPage = () => (
	<Layout>
		<SEO />
		<Screen bottomSpace><Hero /></Screen>
		<Screen free light><Partnership /></Screen>
		<Screen dark><Why /></Screen>
		<Screen><VideoSection /></Screen>
		<FAQ></FAQ>
	</Layout>
)

export default IndexPage
