import React from "react"

import "./why.css"

const Why = () => {
  	return (
		<div className="why">
			<h2 className="primary">Why Podback exists</h2>
			<div className="why-content">
				<div className="why-col">
					<img className="why-logo" alt="" src="/why-logo.svg" />
				</div>
				<div className="why-col">
					<div>
						<p className="light why-content-copy">At Podback we believe we all have a role to play in looking after our environment. Our mission is to create a world where every coffee pod enjoyed is recycled.</p>
						<p className="light why-content-copy">By bringing the entire coffee industry together including retailers and collaborating with local authorities and other recycling service partners, Podback aims to provide easy solutions for everyone to recycle their coffee pods.</p>
						<p className="light why-content-copy">Podback launches early next year, so watch this space…</p>
						<p className="light why-content-copy">For more information about Podback <a href="/podbackpressrelease201120.pdf" target="_blank">click here</a></p>
						<p className="light why-content-copy">For any media enquiries please contact<br/>podback@headlandconsultancy.com</p>
					</div>
				</div>
			</div>
		</div>
	)
}


export default Why
