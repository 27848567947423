import React, { useState } from "react"

import "./faq.css"

const FAQItem = ({title, text}) => {

	const [open, setOpen] = useState(false);

	return (
		<div className="faq-item">
			<div role="button" aria-hidden="true" className="faq-item-title" onKeyDown={(event) => {setOpen(!open)}} onClick={(event) => {setOpen(!open)}}>{title}</div>
			{open && <div className="faq-item-text" dangerouslySetInnerHTML={{__html: text}}></div>}
			<div className="faq-item-toggle">
				<div></div>
				{!open && <div></div>}
			</div>
		</div>
	)
}

const FAQ = () => {

	const items = [
		{
			title: "What is Podback?",
			text: `Podback is a UK-wide, end-to-end, category-wide recycling programme for coffee pods.<br/>
			It is founded by Nestlé and Jacobs Douwe Egberts (JDE UK, owned by JDE Peet’s Group) the two biggest players in the UK coffee market, who between them own Nespresso, NESCAFÉ Dolce Gusto and Tassimo.<br/>
			It aims to provide simple and easy ways to recycle pods - including via Collect+, kerbside collection, and retailer ‘handover at home’ schemes - regardless of brand.`
		},
		{
			title: "Why have you launched it?",
			text: `We want people to be able to enjoy the great taste provided by coffee pods, knowing there is a convenient way to recycle them.<br/>
			While many of Nestlé and JDE UK’s coffee pod brands, including Nespresso, NESCAFÉ Dolce Gusto and Tassimo, already offer recycling programmes, we recognise that the key to driving participation is creating a single consumer solution across the category, with simple and clear options for consumers.<br/>
			Our vision is a world where every coffee pod enjoyed is easily recycled by consumers.`
		},
		{
			title: "When will consumers be able to use the scheme?",
			text: `We’re aiming for Podback to be live in early 2021.`
		},
		{
			title: "How will consumers be able to take part?",
			text: `Podback offers several easy ways for consumers to recycle their coffee pods.<br/>
			When the scheme launches, consumers will be able to take part through Collect+ delivered by Yodel. For this, they will just need to request recycling bags from participating brands and retailers. Once they fill these bags with their used coffee pods, they can then drop them off at more than 6,500 Collect+ locations around the UK, which will then be picked up and taken to be recycled. These are located in local stores, open early until late, seven days a week.<br/>
			Our long term aim to offer kerbside collection for consumers. We are pleased that Exeter City Council, Cheltenham Borough Council and South Derbyshire District Council are in advanced discussions with Podback to become the first confirmed partners, meaning that from the launch of Podback, over 330,000 residents living in these areas will be able to have their pods collected alongside their household waste and recycling. We are in positive talks with several other local authorities, and hope to have this service in place across additional areas from launch.<br/>
			We’re also in positive discussions with major retailers to facilitate a ‘handover at home’ option, meaning that consumers will be able to have their used coffee pods collected for recycling when they have their groceries delivered. While this is unlikely to be ready for launch, we are working hard to make this a reality as soon as possible.`
		},
		{
			title: "Will other plastic and aluminium pods be accepted through Podback – beyond Nestlé and JDE UK branded pods?",
			text: `Podback is an industry scheme and all manufacturers of pods, both brands and retailers, are invited and encouraged to join this scheme.<br/>
			All pods produced by members of Podback, whether they are aluminium or plastic, will be accepted and recycled via the Podback programme.`
		},
		{
			title: "What happens to the schemes consumers currently use?",
			text: `For now, consumers should continue to use their chosen recycling service as normal. We’ll share further details of how to use Podback early next year.`
		},
	]

  	return (
		<div className="faq">
			<div className="faq-title">
				<h2 className="primary">FAQs</h2>
			</div>
			<div className="faq-content">
				{items.map((item) => 
					<FAQItem key={item.title} title={item.title} text={item.text} />
				)}
			</div>
		</div>
	)
}


export default FAQ
