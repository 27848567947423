import React from "react"

import "./hero.css"

const Hero = () => {

	const style = {
		backgroundImage: "url(/home_bg.svg)"
	};

  	return (
		<div className="hero">
			<div className="hero-bg" style={style}></div>
			<div className="down">
				<img alt="" src="/down-arrow.svg" width="30" />
			</div>
		</div>
	)
}


export default Hero
