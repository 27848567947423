import React from "react"

import "./partnership.css"

const Partnership = () => {
  	return (
		<div className="partnership">
			<h2 className="primary">Our Mission</h2>

			<div className="copy">
				<p>Podback is the coffee pod recycling service.</p>
				<p>Created by the two biggest names in the UK coffee industry, Nestlé and JDE, who, together, own Nespresso, NESCAFÉ Dolce Gusto and Tassimo.</p>
			</div>
			
			<div className="logos">
				<div className="logo">
					<img alt="" src="/nespreso.svg" width="200" />
				</div>
				<div className="logo">
					<img alt="" src="/dolcegusto.svg" width="90" />
				</div>
				<div className="logo">
					<img alt="" src="/tassimo.svg" width="186" />
				</div>
			</div>
		</div>
	)
}


export default Partnership
