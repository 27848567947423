import React from 'react';

import styles from './screen.module.css';

const Screen = ({small, children, dark, light, free, bottomSpace}) => {

	let classes = styles.screen;
	if(light) classes += ` ${styles.light}`;
	if(dark) classes += ` ${styles.dark}`;
	if(small) classes += ` ${styles.small}`;
	if(free) classes += ` ${styles.free}`;
	if(bottomSpace) classes += ` ${styles.bottomSpace}`;

	return (
		<div className={classes}>
			{children}
		</div>
	);
};

export default Screen;