import React, { useRef, useState } from "react"

import "./videosection.css"

const VideoSection = () => {

	const videoRef = useRef();
	const [playing, setPlaying] = useState(false);

	const playVideo = () => {
		setPlaying(true);
		videoRef && videoRef.current.play();
        console.log("playVideo -> videoRef", videoRef)
	}

  	return (
		<div className="videosection">
			
			<div className="video-title">
				<h2 className="light">Watch the video</h2>
			</div>
			
			<div className="video-wrapper">
				<video controls ref={videoRef}>
					<source src="/podback.mp4" width="1024" height="576" hidden type="video/mp4" />
				</video>
				{!playing && <div className="video-play" onClick={() => {playVideo()}}></div>}
			</div>

			
		</div>
	)
}


export default VideoSection
